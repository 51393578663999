import "../assets/styles/buttons.css"
import { FormEvent, useRef, useState } from "react";
import processApiProxy from "../api/proxessApiProxy";
import { Button, FormControl, Input, InputGroup, InputRightElement, useToast, Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import matchStringType from "../utils/stringRegex";
import { addDays, format } from "date-fns"
import arrowRight from "../assets/icons/arrow_forward.svg"
import { searchCveRepository, searchIpRepository, searchPathRepository, searchTagRepository } from "../api/searchRepository";
import { AxiosResponse } from "axios";

type SearchInputProps = {
    search: string;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
    pulseRef?: React.RefObject<HTMLDivElement>
};

export default function SearchInput({ search, setSearch, pulseRef }: SearchInputProps) {
    const toast = useToast()
    const navigate = useNavigate();
    const triedAllTime = useRef<boolean>(false);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        let response: AxiosResponse;
        try {
            const dateNow = new Date();
            const fromTime = Math.ceil(addDays(dateNow, -30).getTime() / 1000)
            switch (matchStringType(search)) {
                case "IPv4":
                    response = (await searchIpRepository.getIpHistory(search, triedAllTime.current ? 0 : fromTime, Math.ceil((+new Date()) / 1000)))
                    if (response && response.data.seenOn.length !== 0) {
                        navigate('/search-ip/' + encodeURIComponent(search), { replace: false, state: { ipHistory: response.data } });
                    } else if (triedAllTime.current) {
                        toast({
                            title: "No results found for " + search,
                            status: "warning",
                            duration: 3000
                        })
                    } else {
                        triedAllTime.current = true
                        handleSubmit(e)
                    }
                    break;
                case "CVE":
                    response = (await searchCveRepository.getCveHistory(search, triedAllTime.current ? 0 : fromTime, Math.ceil((+new Date()) / 1000)))
                    if (response && response.data.seenOn.length !== 0) {
                        navigate('/cve/' + encodeURIComponent(search), { replace: false, state: { ipHistory: response.data } });
                    } else if (triedAllTime.current) {
                        toast({
                            title: "No results found for " + search,
                            status: "warning",
                            duration: 3000
                        })
                    } else {
                        triedAllTime.current = true
                        handleSubmit(e)
                    }
                    break;
                case "UnixPath":
                case "WindowsPath":
                    response = (await searchPathRepository.getPathHistory(search, triedAllTime.current ? 0 : fromTime, Math.ceil((+new Date()) / 1000)));
                    if (response && response.data.seenOn.length !== 0) {
                        navigate('/path/' + encodeURIComponent(search), { replace: false, state: { ipHistory: response.data } });
                    } else if (triedAllTime.current) {
                        toast({
                            title: "No results found for " + search,
                            status: "warning",
                            duration: 3000
                        })
                    } else {
                        triedAllTime.current = true
                        handleSubmit(e)
                    }
                    break;
                case "Tag":
                    response = (await searchTagRepository.getTagHistory(search, triedAllTime.current ? 0 : fromTime, Math.ceil((+new Date()) / 1000)));
                    if (response && response.data.seenOn.length !== 0) {
                        navigate('/tag/' + encodeURIComponent(search), { replace: false, state: { ipHistory: response.data } });
                    } else if (triedAllTime.current) {
                        toast({
                            title: "No results found for " + search,
                            status: "warning",
                            duration: 3000
                        })
                    } else {
                        triedAllTime.current = true
                        handleSubmit(e)
                    }
                    break;
                default:
                    break;
            }

            // if (response && response.cve_id) {
            //     navigate('/cve/' + search, { replace: false, state: { response } });
            // }

        } catch (e: any) {

            if (e.response.status === 403) {
                toast({
                    title: "You have no more free requests left",
                    status: "error",
                    duration: 3000
                })
                return;
            }

            toast({
                title: e.response.data.error ? e.response.data.error : e.code,
                status: "error",
                duration: 3000
            })
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <FormControl ref={pulseRef}>
                <InputGroup className="searchInputWrapper1">
                    <Input onChange={(e) => setSearch((e.target.value))} value={search} placeholder='Search by IP, CVE, Path, Vendor' size='lg' />
                    <InputRightElement className="input-string-indicator" style={{ height: "100%", opacity: matchStringType(search) ? 1 : 0 }}>
                        <Button type="submit" className="button-submit1" borderRightRadius={"4px"} height="100%">Search {matchStringType(search) || ""}<Image src={arrowRight} /></Button>
                    </InputRightElement>
                </InputGroup>
            </FormControl>
        </form>
    )
}