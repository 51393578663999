import { useEffect, useState } from "react";
import { callApiProxy } from "../api/callApiProxy";
import { Flex, Heading, Text } from "@chakra-ui/react";
import CustomPieChart from "../components/CustomPieChart";

interface LatestTags {
    latest_time: string;
    count: number;
    tags: string[]
}
interface LatestCves {
    latest_time: string;
    count: number;
    cves: string[]
}

export default function Search() {
    const [latestTags, setLatestTags] = useState<LatestTags[]>()
    const [latestCves, setLatestCves] = useState<LatestCves[]>()
    useEffect(() => {
        callApiProxy('latest-tags?', "GET", { data: '' })
            .then((data: LatestTags[]) => setLatestTags(
                data.sort((a: LatestTags, b: LatestTags) => a.count - b.count)
            ));
            
        callApiProxy('latest-cves?', "GET", { data: '' })
        .then((data: LatestCves[]) => setLatestCves(
            data.sort((a: LatestCves, b: LatestCves) => a.count - b.count)
        ));
    }, []);

    if ((!latestTags || latestTags.length === 0) || (!latestCves || latestCves.length === 0)) return <></>
    return (
        <Flex w="81%" flexDir={"column"} margin="0 auto" rowGap={30} mt={30}>
            <Heading as='h1' size='4xl' className={"cveId"} textAlign={"center"}>LATEST ATTACKS</Heading>
            <Flex>
                <div className="cvePage-pies-wrapper" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <CustomPieChart data={latestCves.map(tag => {return {...tag, tags: tag.cves.join(", ")}})} heading={"Latest CVEs From Attacks"} />
                    </div>
                    <div>
                        <CustomPieChart data={latestTags.map(tag => {return {...tag, tags: tag.tags.join(", ")}})} heading={"Latest Tags From Attacks"} />
                    </div>
                </div>
            </Flex>
        </Flex>
    )
}