import { Button, Flex, FormControl, Input, useToast } from "@chakra-ui/react";
import { supabase } from "../api/supabase";
import { useEffect, useState } from "react";

export default function SetupMFA() {
    const [mfaQr, setMfaQr] = useState<string | undefined>(undefined);
    const [verifyCode, setVerifyCode] = useState<string>("");
    const [factorId, setFactorId] = useState<string>("")
    const [error, setError] = useState('') // holds an error message
    const toast = useToast();

    const enableMFA = () => {

        setError('');
        if (factorId.length < 6 || verifyCode.length < 6) {
            toast({
                title: "Please enter a valid number",
                status: "warning",
                duration: 3000
            })
            return;
        }

        (async () => {
            const challenge = await supabase.auth.mfa.challenge({ factorId })
            if (challenge.error) {
                setError(challenge.error.message)
                // throw challenge.error
                return;
            }

            const challengeId = challenge.data.id

            const verify = await supabase.auth.mfa.verify({
                factorId,
                challengeId,
                code: verifyCode,
            })
            if (verify.error) {
                setError(verify.error.message)
                // throw verify.error
                return;
            } else {
                window.location.href = '/';
            }
        })()
    }

    useEffect(() => {
        supabase.auth.mfa.enroll({
            factorType: 'totp',
            friendlyName: "Sphere Alphatechs",
            issuer: "Sphere Alphatechs"
        }).then(res => {
            if (!res.data) return;

            setMfaQr(res.data?.totp.qr_code)
            setFactorId(res.data.id)
        }).catch(error => {

        })
    }, [])

    useEffect(() => {
        if (!error) return;
        toast({
            title: error,
            status: "warning",
            duration: 3000
        })
    }, [error])
    return (
        <>
            <Flex margin="0 auto" alignItems="center" width="600px" direction={"column"}>
                {mfaQr && <img src={mfaQr} height="300px" width="300px" alt="mfa-qr" style={{
                    margin: "60px 0px",
                    padding: "90px 90px",
                    boxSizing: "content-box",
                    background: "#ffffff",
                    borderRadius: "33px",
                }}
                />}
                <Input
                    type="text"
                    placeholder="Enter Code From Authenticator App"
                    value={verifyCode}
                    onChange={(e) => setVerifyCode(e.target.value.trim())}
                />
                <Button mt={30} onClick={() => enableMFA()}>
                    Enable Two-Factor Authentication
                </Button>
            </Flex>
        </>
    )
}