import { AreaChart, ResponsiveContainer, XAxis, YAxis, Tooltip, Legend, Area } from "recharts";

interface CustomLineGraphProps {
    groupedLogs: any;
    aspect: number;
    handleMouseLeave?: () => void;
  }

const CustomLineGraph: React.FC<CustomLineGraphProps> = ({ groupedLogs, handleMouseLeave, aspect }) => {
    return (
      <ResponsiveContainer width="100%" aspect={aspect} className="line-graph-type1">
        <AreaChart data={groupedLogs}>
          <XAxis dataKey="day" axisLine={{ stroke: "rgba(17, 17, 17, 1)" }} />
          <YAxis axisLine={{ stroke: "rgba(17, 17, 17, 1)" }} />
          <Tooltip
            label="Count"
            cursor={false}
            content={<CustomTooltip />}
          />
          <Legend />
          <Area
            dataKey="count"
            stroke="rgb(228, 30, 32, 0.39)"
            fill="rgba(246, 0, 0, 0.3)"
            // onMouseLeave={handleMouseLeave}
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  };
  
  export default CustomLineGraph;

  
const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ backgroundColor: 'rgba(0,0,0,0.6)', padding: '10px', color: "#5b5b5b", border: '1px solid rgba(0,0,0,0.6)' }}>
                <p>{`${label}`}</p>
                <p>{`Attacks: ${payload[0].value}`}</p>
            </div>
        );
    }

    return null;
};