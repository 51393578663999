import { Button, FormControl, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast } from "@chakra-ui/react";
import { useRef, useState } from "react";
import "../assets/styles/modal.css";
import { supabase } from "../api/supabase";

interface ISignUpFormData {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    password: string;
}

interface ModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export default function Signup({ open, setOpen }: ModalProps) {
    const initialRef = useRef(null)
    const toast = useToast()
    const [formData, setFormData] = useState<ISignUpFormData>({
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        password: "!!!AAA111",
    })

    const handleSignUp = async (e: any) => {
        e.preventDefault();
        const { error, data } = await supabase.auth.signUp({
            email: formData.email,
            password: formData.password,
            options: {
                data: {
                    firstname: formData.firstname,
                    lastname: formData.lastname,
                    phone: formData.phone,
                }
            }
        });
        await supabase.auth.signOut();
        if (error) {
            toast({
                title: error.message,
                status: "warning",
                duration: 3000
            })
        } else {
            toast({
                title: "Your request has been sent for manual approval.",
                status: "success",
                duration: 3000
            })
        }
    }

    return (
        <Modal isOpen={open} initialFocusRef={initialRef} onClose={() => { setOpen(false) }} isCentered={true}>
            <ModalOverlay />
            <ModalContent className="modal-content">
                <ModalHeader>Sign Up</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl>
                        <form onSubmit={(e) => handleSignUp(e)}>
                            <ModalBody>
                                <Input
                                    ref={initialRef}
                                    placeholder='First Name'
                                    size='lg'
                                    marginBottom="3"
                                    value={formData.firstname}
                                    onChange={(e) => setFormData({ ...formData, firstname: e.target.value })}
                                />
                                <Input
                                    placeholder='Last Name'
                                    size='lg'
                                    marginBottom="3"
                                    value={formData.lastname}
                                    onChange={(e) => setFormData({ ...formData, lastname: e.target.value })}
                                />
                                <Input
                                    placeholder='Email'
                                    size='lg'
                                    marginBottom="3"
                                    value={formData.email}
                                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                />
                                <Input
                                    placeholder='Phone'
                                    size='lg'
                                    marginBottom="3"
                                    value={formData.phone}
                                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                                />
                            </ModalBody>
                        </form>
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button mr={3} onClick={handleSignUp} type="submit">
                        Sign Up
                    </Button>
                    <Button mr={3} onClick={() => setOpen(false)}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}