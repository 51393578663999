import React, { useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import ProtectedRoute from './auth/ProtectedRoute';
import "./assets/styles/global.css";
import { useAuthStore } from './store/useAuthStore';
import Search from './pages/Search';
import CVE from './pages/CVE';
import Latest from './pages/Latest';
import SearchIP from './pages/SearchIP';
import SearchPath from './pages/SearchPath';
import SearchTag from './pages/SearchTag';
import ResetPassword from './pages/ResetPassword';
import SetupMFA from './pages/SetupMFA';

function App() {
  const { checkSession, loading } = useAuthStore(state => ({ checkSession: state.checkSession, loading: state.loading }));

  useEffect(() => {
    checkSession();
  }, [checkSession]);

  if (loading) {
    return <div>Loading...</div>; // Or a more sophisticated loader/spinner
  }

  return (
    <ChakraProvider theme={theme}>
        <Router>
          <Navbar />
          <Routes>
            <Route index path="/" element={<Home />} />
            <Route index path="/latest" element={<Latest />} />
            <Route path="/search/:searchString" element={<Search />} />
            <Route path="/search-ip/:searchString" element={<SearchIP />} />
            <Route path="/tag/:searchString" element={<SearchTag />} />
            <Route path="/path/:searchString" element={<SearchPath />} />
            <Route path="/cve/:searchString" element={<CVE />} />
            <Route path="/reset-password" element={<ProtectedRoute><ResetPassword /></ProtectedRoute>} />
            <Route path="/setup-mfa" element={<ProtectedRoute><SetupMFA /></ProtectedRoute>} />
            <Route path="/dashboard" element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            } />
          </Routes>
        </Router>
    </ChakraProvider>
  );
}

export default App;
