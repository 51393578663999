const Loading = ({show}: {show: boolean}) => {
    return (
        <div style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            background: "rgba(0,0,0,0.3)",
            zIndex: "999",
            display: show ? "block" : "none"
        }}>

        </div>
    )
}

export default Loading;