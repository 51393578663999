export const filterDateOptions = {
    all: { index: -1, string: "All" },
    day: { index: 1, string: "Today" },
    week: { index: 7, string: "This Week" },
    month: { index: 30, string: "This Month" },
}

export const dateRangeConfig = {
    dateNavBtnProps: { background: "rgba(34, 34, 34, 1)" },
    dayOfMonthBtnProps: {
        defaultBtnProps: { 
            background: "rgba(34, 34, 34, 1)",
            _hover: {
                background: "rgba(45, 45, 45, 1)",
            }
        },
        isInRangeBtnProps: { style: { background: "rgba(60, 60, 60, 1)" } },
        selectedBtnProps: { style: { background: "rgba(60, 60, 60, 1)" } },
        todayBtnProps: { background: "rgba(34, 34, 34, 1)" }
    },
    inputProps: { background: "rgba(34, 34, 34, 1)" },
    popoverCompProps: {
        popoverContentProps: { background: "rgba(34, 34, 34, 1)" },
        popoverBodyProps: { background: "rgba(34, 34, 34, 1)" }
    },
    calendarPanelProps: {
        wrapperProps: { background: "rgba(34, 34, 34, 1)" },
        contentProps: { background: "rgba(34, 34, 34, 1)" },
        headerProps: { background: "rgba(34, 34, 34, 1)" },
        dividerProps: { background: "rgba(34, 34, 34, 1)" },
    },
    weekdayLabelProps: { background: "rgba(34, 34, 34, 1)" },
    dateHeadingProps: { background: "rgba(34, 34, 34, 1)" },
    triggerBtnProps: {
        style: {
            padding: "12px 30px",
            alignSelf: "end",
            background: "rgba(34, 34, 34, 1)",
            zIndex: 1,
            borderRadius: "9px",
            border: "unset"
        }
    }
}