import { Accordion, Button, Flex, Heading, Menu, MenuButton, MenuItem, MenuList, useToast } from "@chakra-ui/react";
import { IoFilterSharp } from "react-icons/io5";
import CustomLineGraph from "../components/CustomLineGraph";
import CustomPieChart from "../components/CustomPieChart";
import CustomAccordionItem from "../components/CustomAccordionItem";
import CustomTable from "../components/CustomTable";
import CVEBg from "../assets/cve-bg.png";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { searchIpRepository } from "../api/searchRepository";
import { addDays, format } from "date-fns";
import groupAttacksByDay from "../utils/groupSeenOn";
import { AxiosResponse } from "axios";
import Pagination from "../components/Pagination";
import Loading from "../components/Loading";
import { endpointTrimmer } from "../utils/utils";
import "../assets/styles/searchCommon.css"
import { IIpHistory, IIpRelatedCves, IIpRelatedTags, IIpGeneralData, IIpCveLogs } from "../types/searchIp";
import { dateRangeConfig, filterDateOptions } from "../constants/date";
import DateDropDown from "../components/DateDropDown";
import { RangeDatepicker } from "chakra-dayzed-datepicker";

export default function SearchIP() {
    const navState = useLocation();
    const navParams = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [ipHistory, setIpHistory] = useState<IIpHistory | undefined>(undefined);
    const [ipRelatedCves, setIpRelatedCves] = useState<IIpRelatedCves | undefined>(undefined);
    const [ipRelatedTags, setIpRelatedTags] = useState<IIpRelatedTags[] | undefined>(undefined);
    const [ipGeneralData, setIpGeneralData] = useState<IIpGeneralData | undefined>(undefined);
    const [ipCveLogs, setIpCveLogs] = useState<{ [key: string]: IIpCveLogs }>({});
    const [activeIndex, setActiveIndex] = useState(-1); // Add state to track hover
    const toast = useToast();
    const [cvesDataPage, setCvesDataPage] = useState<number>(1);
    const [cvesLogsPage, setCvesLogsPage] = useState<number>(1);
    const [accordionOpenedCve, setAccordionOpenedCve] = useState<number>()
    const [filterDate, setFilterDate] = useState(filterDateOptions.month)
    const [selectedDates, setSelectedDates] = useState<Date[]>([]);

    let endDate = useRef<number>(Math.ceil((+new Date()) / 1000));
    let startDate = useRef<number>(filterDate.index !== -1 ? Math.ceil(addDays(new Date(endDate.current * 1000), -filterDate.index).getTime() / 1000) : 0);

    function getIpCvesData(page: number) {
        searchIpRepository.getIpCvesData(navParams.searchString!!, startDate.current, endDate.current).then((response: AxiosResponse<IIpGeneralData>) => {
            setIpGeneralData(response.data);
        })
    }

    async function getCveLogs(tag_id: number, page: number = 1) {
        const cveResponse = (await searchIpRepository.getIpGeneralLogs(tag_id, navParams.searchString!!, startDate.current, endDate.current, page)).data

        setIpCveLogs(prevState => ({
            ...prevState,
            [tag_id]: cveResponse
        }));
    }

    async function prepareQueryByDate() {
        if (navParams.searchString) {
            searchIpRepository.getIpHistory(navParams.searchString, startDate.current, endDate.current).then((response: AxiosResponse<IIpHistory>) => {
                if (response.data.seenOn.length !== 0) {
                    (response.data.seenOn as any) = groupAttacksByDay(response.data.seenOn);
                    setIpHistory(response.data)

                    searchIpRepository.getIpRelatedCves(navParams.searchString!!, startDate.current, endDate.current).then((response: AxiosResponse<IIpRelatedCves>) => {
                        setIpRelatedCves(response.data);
                    })

                    searchIpRepository.getIpRelatedTags(navParams.searchString!!, startDate.current, endDate.current).then((response: AxiosResponse<IIpRelatedTags[]>) => {
                        setIpRelatedTags(response.data);
                    })

                    getIpCvesData(cvesDataPage);
                } else if (filterDate.index !== -1) {
                    toast({
                        title: "No results found within " + filterDate.index + " days",
                        status: "warning",
                        duration: 3000
                    })
                    setFilterDate(filterDateOptions.all)
                } else {
                    toast({
                        title: "No results found for " + navParams.searchString,
                        status: "warning",
                        duration: 3000
                    })
                }
            })
        }
    }

    useEffect(() => {
        endDate.current = Math.ceil((+new Date()) / 1000);
        startDate.current = filterDate.index !== -1 ? Math.ceil(addDays(new Date(endDate.current * 1000), -filterDate.index).getTime() / 1000) : 0;
        prepareQueryByDate()
    }, [navParams.searchString, filterDate]);

    useEffect(() => {
        if (selectedDates.length === 2) {
            startDate.current = Math.ceil(+selectedDates[0] / 1000)
            endDate.current = Math.ceil((+selectedDates[1] / 1000) + 86399);
            // console.log([new Date(startDate.current * 1000), new Date(endDate.current * 1000)])
            prepareQueryByDate()
        }
    }, [selectedDates]);

    const handleMouseLeave = () => {
        setActiveIndex(-1);
    };

    useEffect(() => {
        ipGeneralData && ipGeneralData.data.forEach(async (cveObj) => {
            (await getCveLogs(cveObj.tag_id, 1));
            setCvesLogsPage(1)
        })
    }, [ipGeneralData])

    useEffect(() => {
        setIsLoading(true);
        searchIpRepository.getIpCvesData(navParams.searchString!!, startDate.current, endDate.current, cvesDataPage).then((res: AxiosResponse<IIpGeneralData>) => {
            setIpGeneralData(res.data)
            setIsLoading(false)
        })
    }, [cvesDataPage])

    useEffect(() => {
        if (!accordionOpenedCve) return;
        setIsLoading(true);
        getCveLogs(accordionOpenedCve, cvesLogsPage).then(() => setIsLoading(false))
    }, [cvesLogsPage])

    return (
        <>
            <Loading show={isLoading} />
            <Flex direction="column" className="cveMainDetails" backgroundImage={CVEBg}>
                <Heading as='h1' size='3xl' className={"searchQueryTitle"} textAlign={"center"}>{navParams.searchString}</Heading>
                {ipHistory &&
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <Flex style={{ margin: "6px 0px 9px auto" }} columnGap={"9px"}>
                            <RangeDatepicker
                                selectedDates={selectedDates}
                                onDateChange={setSelectedDates}
                                propsConfigs={dateRangeConfig}
                            />
                            <DateDropDown setFilterDate={setFilterDate} filterDate={filterDate} />
                        </Flex>
                        <CustomLineGraph groupedLogs={ipHistory.seenOn} handleMouseLeave={handleMouseLeave} aspect={3} />
                    </div>
                }
                <div className="cvePage-pies-wrapper" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <div>
                        {ipRelatedCves && <CustomPieChart data={ipRelatedCves} heading={"CVEs Related to this IP"} />}
                    </div>
                    <div>
                        {ipRelatedTags && <CustomPieChart data={
                            ipRelatedTags.map(pieSlice => { return { tag: pieSlice.tags.join(', '), count: pieSlice.count } })
                        } heading={"Tags Related to this IP"} />}
                    </div>
                </div>
                <Accordion allowToggle width={"100%"}>
                    {ipGeneralData && ipGeneralData.data.map((cveObj, i: number) =>
                        <CustomAccordionItem key={i}
                            accordionTitle={cveObj.cve === "" ? "Other Attacks" : cveObj.cve}
                            accordionTags={cveObj.tags}
                            accordionText={`${cveObj.attacks} Attacks - Endpoint: ${endpointTrimmer(cveObj.endpoint)} - Latest attack: ${format(new Date(cveObj.latestAttack * 1000), "yyyy-MM-dd HH:mm:ss")}`}
                            onClick={() => { setAccordionOpenedCve(cveObj.tag_id) }}>
                            {ipGeneralData && Object.hasOwn(ipCveLogs, cveObj.tag_id) && ipGeneralData.data.length > 0 &&
                                // <CustomTable
                                //     headings={["DATETIME", "PATH", "USER AGENT", "REQUEST QUERYSTRING"]}
                                //     rowsData={[["a1", "a2", "a3"], ["a1", "a2", "a3"], ["a1", "a2", "a3"]]} />
                                <>
                                    <CustomTable
                                        headings={["DATETIME", "PATH", "USER AGENT", "REQUEST QUERYSTRING"]}
                                        rowsData={
                                            ipCveLogs[cveObj.tag_id].logs.map(log => [format(new Date(log.time * 1000), "yyyy-MM-dd HH:mm:ss"), log.path, log.userAgent, log.queryString])
                                        } />
                                    <Flex className="data-logs-pagination-wrapper" justifyContent={"space-between"}>
                                        <Pagination
                                            currentPage={cvesLogsPage}
                                            setPage={setCvesLogsPage}
                                            maxPage={ipCveLogs[cveObj.tag_id].pagination.maxPage}
                                        />
                                    </Flex>
                                </>
                            }
                        </CustomAccordionItem>
                    )}
                </Accordion>
                {ipGeneralData &&
                    <Flex className="pagination-wrapper" w={"100%"} justifyContent={"space-between"}>
                        <Pagination currentPage={cvesDataPage} setPage={setCvesDataPage} maxPage={ipGeneralData.pagination.maxPage} />
                    </Flex>
                }
            </Flex>
        </>
    )
}