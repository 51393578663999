import axios from 'axios';
import { supabase } from './supabase';

const BASE_URL = process.env.REACT_APP_PROXY;

export const dashboardRepo = {
    getTopAsn: async () => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/api/top-asn-global?`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    },
    getTopCountries: async () => {
        try {
            const session = supabase.auth.getSession();
            const accessToken = (await session).data.session?.access_token;
            return await axios.get(`${BASE_URL}/api/top-countries-global?`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error calling proxy API:', error);
            throw error;
        }
    }
}