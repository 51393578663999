import { Accordion, Button, Flex, Heading, Menu, MenuButton, MenuItem, MenuList, useToast, Text, Link } from "@chakra-ui/react";
import { IoFilterSharp } from "react-icons/io5";
import CustomLineGraph from "../components/CustomLineGraph";
import CustomPieChart from "../components/CustomPieChart";
import CustomAccordionItem from "../components/CustomAccordionItem";
import CustomTable from "../components/CustomTable";
import CVEBg from "../assets/cve-bg.png";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { searchPathRepository } from "../api/searchRepository";
import { addDays, format } from "date-fns";
import groupAttacksByDay from "../utils/groupSeenOn";
import { AxiosResponse } from "axios";
import Pagination from "../components/Pagination";
import Loading from "../components/Loading";
import { endpointTrimmer } from "../utils/utils";
import "../assets/styles/searchCommon.css"
import { IPathHistory, IPathRelatedIps, IPathRelatedCves, IPathIpsData, IPathIpLogs, IPathDetails } from "../types/searchPath";
import DateDropDown from "../components/DateDropDown";

export default function SearchPath() {
    const navState = useLocation();
    const navParams = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [pathHistory, setPathHistory] = useState<IPathHistory | undefined>(undefined);
    const [pathRelatedIps, setPathRelatedIps] = useState<IPathRelatedIps | undefined>(undefined);
    const [pathDetails, setPathDetails] = useState<IPathDetails | undefined>(undefined);
    const [pathIpsData, setPathIpsData] = useState<IPathIpsData | undefined>(undefined);
    const [pathIpLogs, setPathIpLogs] = useState<{ [key: string]: IPathIpLogs }>({});
    const [activeIndex, setActiveIndex] = useState(-1); // Add state to track hover
    const toast = useToast();
    const [ipsDataPage, setIpsDataPage] = useState<number>(1);
    const [ipsLogsPage, setIpsLogsPage] = useState<number>(1);
    const [accordionOpenedIp, setAccordionOpenedIp] = useState<string>("")

    const filterDateOptions = {
        all: { index: -1, string: "All" },
        day: { index: 1, string: "Today" },
        week: { index: 7, string: "This Week" },
        month: { index: 30, string: "This Month" },
    }
    const [filterDate, setFilterDate] = useState(filterDateOptions.month)
    let endDate: number = Math.ceil((+new Date()) / 1000);
    let startDate: number = filterDate.index !== -1 ? Math.ceil(addDays(new Date(endDate * 1000), -filterDate.index).getTime() / 1000) : 0;

    function getPathIpsData(page: number) {
        searchPathRepository.getPathIpsData(navParams.searchString!!, startDate, endDate).then((response: AxiosResponse<IPathIpsData>) => {
            setPathIpsData(response.data);
        })
    }

    async function getIpLogs(ip: string, page: number = 1) {
        const pathResponse = (await searchPathRepository.getPathIpLogs(navParams.searchString!!, ip, startDate, endDate, page)).data

        setPathIpLogs(prevState => ({
            ...prevState,
            [ip]: pathResponse
        }));
    }

    useEffect(() => {
        if (navParams.searchString) {
            searchPathRepository.getPathHistory(navParams.searchString, startDate, endDate).then((response: AxiosResponse<IPathHistory>) => {
                if (response.data.seenOn.length !== 0) {
                    (response.data.seenOn as any) = groupAttacksByDay(response.data.seenOn);
                    setPathHistory(response.data)

                    searchPathRepository.getPathDetails(navParams.searchString!!).then((response: AxiosResponse<IPathDetails>) => {
                        setPathDetails(response.data);
                    })

                    searchPathRepository.getPathRelatedIps(navParams.searchString!!, startDate, endDate).then((response: AxiosResponse<IPathRelatedIps>) => {
                        setPathRelatedIps(response.data);
                    })

                    getPathIpsData(ipsDataPage);
                } else if (filterDate.index !== -1) {
                    toast({
                        title: "No results found within " + filterDate.index + " days",
                        status: "warning",
                        duration: 3000
                    })
                    setFilterDate(filterDateOptions.all)
                } else {
                    toast({
                        title: "No results found for " + navParams.searchString,
                        status: "warning",
                        duration: 3000
                    })
                }
            })
        }
    }, [navParams.searchString, filterDate]);

    const handleMouseLeave = () => {
        setActiveIndex(-1);
    };

    useEffect(() => {
        pathIpsData && pathIpsData.data.forEach(async (pathObj) => {
            (await getIpLogs(pathObj.ip, 1));
            setIpsLogsPage(1)
        })
    }, [pathIpsData])

    useEffect(() => {
        setIsLoading(true);
        searchPathRepository.getPathIpsData(navParams.searchString!!, startDate, endDate, ipsDataPage).then((res: AxiosResponse<IPathIpsData>) => {
            setPathIpsData(res.data)
            setIsLoading(false)
        })
    }, [ipsDataPage])

    useEffect(() => {
        setIsLoading(true);
        accordionOpenedIp && getIpLogs(accordionOpenedIp, ipsLogsPage).then(() => setIsLoading(false))
    }, [ipsLogsPage])

    return (
        <>
            <Loading show={isLoading} />
            <Flex direction="column" className="cveMainDetails" backgroundImage={CVEBg}>
                <Heading as='h1' size='3xl' className={"searchQueryTitle"} textAlign={"center"}>{navParams.searchString}</Heading>
                {pathHistory &&
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <DateDropDown setFilterDate={setFilterDate} filterDate={filterDate} />
                        <CustomLineGraph groupedLogs={pathHistory.seenOn} handleMouseLeave={handleMouseLeave} aspect={3} />
                    </div>
                }
                <div className="cvePage-pies-wrapper" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <div>
                        {pathRelatedIps && <CustomPieChart data={pathRelatedIps} heading={"IPs Related to this Path"} />}
                    </div>
                    {/* START INFO COMPONENT */}
                    <div className="outerBgBox infoComponentWrapper" style={{ width: "45%", display: "flex", flexDirection: "column", alignItems: "stretch", justifyContent: "flex-start" }}>
                        <div className="innerBgBox" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", padding: "33px" }}>
                            <div>
                                <Text fontSize="36px" fontWeight={700}>Tags</Text>
                                <Flex marginTop={"12px"} direction={"column"} flexDirection={"row"} flexWrap="wrap" rowGap="12px">
                                    {pathDetails && pathDetails.tags.map(tag =>
                                        <Link
                                            key={tag}
                                            className="customAccordionTag"
                                            fontSize="21px"
                                            borderRadius={"6px"}
                                            padding={"3px 12px"}
                                            fontWeight={400}
                                            href={`/tag/${tag}`}
                                            onClick={(e) => { e.preventDefault(); window.location.href = `/tag/${tag}` }}
                                        >{tag}</Link>
                                    )}
                                </Flex>
                                <br />
                                <br />
                                <Text fontSize="36px" fontWeight={700}>CVEs</Text>
                                {pathDetails && pathDetails.cve && pathDetails.cve.map((cve) =>
                                    <Link
                                        key={cve}
                                        className="customAccordionTag"
                                        fontSize="21px"
                                        borderRadius={"6px"}
                                        padding={"3px 12px"}
                                        fontWeight={400}
                                        href={`/cve/${cve}`}
                                        onClick={(e) => { e.preventDefault(); window.location.href = `/cve/${cve}` }}
                                    >{cve}</Link>
                                )}
                            </div>
                            <br /><br />
                            <Flex className="cveTotalAttempts" alignItems={"center"} justifyContent={"space-between"} borderRadius={"15px"} padding={"21px 30px"} background={"linear-gradient(90deg, #900000 31.23%, #F60000 100%)"}>
                                <Text fontSize="30px">All Time Total Attempts</Text>
                                <Text fontSize="36px" fontWeight={"600"} letterSpacing={"9px"}>{pathDetails?.totalAttempts}</Text>
                            </Flex>
                        </div>
                    </div>
                    {/* END INFO COMPONENT */}
                </div>
                <Accordion allowToggle width={"100%"}>
                    {pathIpsData && pathIpsData.data.map((pathObj, i: number) =>
                        <CustomAccordionItem key={i}
                            accordionTitle={pathObj.ip === "" ? "Other Attacks" : pathObj.ip}
                            accordionText={`${pathObj.attacks} Attacks - ${pathObj.country} - ${pathObj.asn} - Latest attack: ${format(new Date(pathObj.latestAttack * 1000), "yyyy-MM-dd HH:mm:ss")}`}
                            onClick={() => { setAccordionOpenedIp(pathObj.ip) }}>
                            {pathIpsData && Object.hasOwn(pathIpLogs, pathObj.ip) && pathIpsData.data.length > 0 &&
                                // <CustomTable
                                //     headings={["DATETIME", "PATH", "USER AGENT", "REQUEST QUERYSTRING"]}
                                //     rowsData={[["a1", "a2", "a3"], ["a1", "a2", "a3"], ["a1", "a2", "a3"]]} />
                                <>
                                    <CustomTable
                                        headings={["DATETIME", "PATH", "USER AGENT", "REQUEST QUERYSTRING"]}
                                        rowsData={
                                            pathIpLogs[pathObj.ip].logs.map(log => [format(new Date(log.time * 1000), "yyyy-MM-dd HH:mm:ss"), log.path, log.userAgent, log.queryString])
                                        } />
                                    <Flex className="data-logs-pagination-wrapper" justifyContent={"space-between"}>
                                        <Pagination
                                            currentPage={ipsLogsPage}
                                            setPage={setIpsLogsPage}
                                            maxPage={pathIpLogs[pathObj.ip].pagination.maxPage}
                                        />
                                    </Flex>
                                </>
                            }
                        </CustomAccordionItem>
                    )}
                </Accordion>
                {pathIpsData &&
                    <Flex className="pagination-wrapper" w={"100%"} justifyContent={"space-between"}>
                        <Pagination currentPage={ipsDataPage} setPage={setIpsDataPage} maxPage={pathIpsData.pagination.maxPage} />
                    </Flex>
                }
            </Flex>
        </>
    )
}