import React from 'react';
import { PieChart, Pie, Tooltip, Sector, Cell, ResponsiveContainer } from 'recharts';
import { Text } from "@chakra-ui/react";
import { Text as ReChartText } from 'recharts';
import { useNavigate } from 'react-router-dom';


const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, name } = props;
  
  let newName = ""
  if (Object.hasOwn(props.payload.payload, "remote_ip")) {
    newName = props.payload.payload.remote_ip
  } else if (Object.hasOwn(props.payload.payload, "tags")) {
    newName = props.payload.payload.tags
  } else {
    newName = payload.name
  }

  // Calculate the position for the bottom text
  const bottomTextY = cy + outerRadius + 20; // Add some padding (20 in this case)

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={"rgba(228, 30, 30, 1)"}
      />
      <text
        x={0}
        y={bottomTextY}
        textAnchor="start"
        style={{fontWeight: 700, fontSize: "15px", fill: "rgba(246, 0, 0, 1)"}}
      >
        {`${newName || name}`}
      </text>
      <text
        x={0}
        y={bottomTextY + 20} // Add some space between the two lines of text
        textAnchor="start"
        fill="#999"
      >
        <a style={{fontWeight: 700, fontSize: "15px"}}>Attacks: </a>
        <a style={{fontWeight: 700, fontSize: "15px", fill: "rgba(246, 0, 0, 1)"}}>{value}</a>
      </text>
    </g>
  );
};


export default function CustomPieChart(props: any) {
  const navigate = useNavigate();

  if (props.data.length === 0)
    return <></>

  const countKey = (() => {
    if (Object.hasOwn(props.data[0], "tag_count") && Object.hasOwn(props.data[0], "tags")) {
      return { countKey: "tag_count", nameKey: "tags" }
    } else if (Object.hasOwn(props.data[0], "tags") && Object.hasOwn(props.data[0], "count")) {
      return { countKey: "count", nameKey: "tags" }
    } else if (Object.hasOwn(props.data[0], "cve") && Object.hasOwn(props.data[0], "count")) {
      return { countKey: "count", nameKey: "cve" }
    } else if (Object.hasOwn(props.data[0], "cve_count")) {
      return { countKey: "cve_count", nameKey: "cve" }
    } else if (Object.hasOwn(props.data[0], "remote_ip")) {
      return { countKey: "count", nameKey: "IP" }
    } else if (Object.hasOwn(props.data[0], "tag")) {
      return { countKey: "count", nameKey: "tag" }
    } else if (Object.hasOwn(props.data[0], "cve")) {
      return { countKey: "count", nameKey: "cve" }
    } else if (Object.hasOwn(props.data[0], "ip")) {
      return { countKey: "count", nameKey: "ip" }
    } else if (Object.hasOwn(props.data[0], "asn")) {
      return { countKey: "count", nameKey: "asn" }
    } else if (Object.hasOwn(props.data[0], "country")) {
      return { countKey: "count", nameKey: "country" }
    }

    return undefined;
  })()

  if (!countKey) return <></>;

  const handlePieClick = (data: any, index: number) => {
    const clickedBar = data.payload.payload;
    console.log(clickedBar)
    if (Object.hasOwn(clickedBar, "tag")) {
      navigate(`/tag/${encodeURIComponent(clickedBar.tag)}`);
    } else if (Object.hasOwn(clickedBar, "cve")) {
      navigate(`/cve/${encodeURIComponent(clickedBar.cve)}`);
    } else if (Object.hasOwn(clickedBar, "ip")) {
      navigate(`/search-ip/${encodeURIComponent(clickedBar.ip)}`);
    }
  };

  const sortedData = props.data.sort((a: any, b: any) => a[countKey.countKey] - b[countKey.countKey]);
  return (
    <div className={"chartWrapper"} style={props.style}>
      <Text fontSize="md" sx={{ padding: "var(--chakra-space-6)", paddingBottom: "0px", fontSize: "21px", fontWeight: 700, color: "rgba(246, 246, 246, 1)" }}>{props.heading}</Text>
      <ResponsiveContainer className={"chartDisplay"} width="100%" aspect={1}>
        <PieChart>
          <Pie
            data={sortedData}
            dataKey={countKey.countKey}
            nameKey={countKey.nameKey}
            activeShape={renderActiveShape}
            cx="50%"
            cy="50%"
            outerRadius={"78%"}
            innerRadius={"48%"}
            stroke={"rgba(34, 34, 34, 1)"}
            fill="rgba(246, 0, 0, 0.3)"
            onClick={handlePieClick}
          // label={(entry) => entry[countKey.nameKey]}
          >
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

const CustomTooltip = (props: any) => {
  if (props.active && props.payload && props.payload.length) {
    if (Object.hasOwn(props.payload[0].payload, "remote_ip")) {
      return <></>
      return (
        <div style={{ backgroundColor: 'rgba(0,0,0,0.6)', padding: '10px', color: "#5b5b5b", border: '1px solid rgba(0,0,0,0.6)' }}>
          <p>{`IP: ${props.payload[0].payload.remote_ip}`}</p>
          <p>{`Attacks: ${props.payload[0].payload.count}`}</p>
        </div>
      );
    } else if (Object.hasOwn(props.payload[0].payload, "tags")) {
      return <></>
      return (
        <div style={{ backgroundColor: 'rgba(0,0,0,0.6)', padding: '10px', color: "#5b5b5b", border: '1px solid rgba(0,0,0,0.6)' }}>
          <p>{`Tag: ${props.payload[0].payload.tags}`}</p>
          <p>{`Attacks: ${props.payload[0].payload.count}`}</p>
        </div>
      );
    }
    return <></>
    return (
      <div style={{ backgroundColor: 'rgba(0,0,0,0.6)', padding: '10px', color: "#5b5b5b", border: '1px solid rgba(0,0,0,0.6)' }}>
        <p>{`Tag: ${props.payload[0].name}`}</p>
        <p>{`Attacks: ${props.payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};