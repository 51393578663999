import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import processApiProxy from "../api/proxessApiProxy";
import { Accordion, Flex, Heading, Icon, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Menu, MenuItem, MenuButton, Button, MenuList, Progress, useToast, Link } from "@chakra-ui/react";
import "../assets/styles/cve.css";
import CVEBg from "../assets/cve-bg.png";
import { ImNewTab } from "react-icons/im";
import CustomPieChart from "../components/CustomPieChart";
import { callApiProxy } from "../api/callApiProxy";
import countByKey, { getTagTimes, groupAndCountByDay } from "../utils/countByKey";
import CustomTreeMap from "../components/CustomTreeMap";
import CustomTable from "../components/CustomTable";
import CustomAccordion from "../components/CustomAccordionItem";
import CustomAccordionItem from "../components/CustomAccordionItem";
import { Area, AreaChart, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { ChevronDownIcon } from "@chakra-ui/icons";
import "../assets/styles/search.css";
import { addDays, format } from "date-fns";
import matchStringType from "../utils/stringRegex";
import { ICVE, ICVEHistory, IGroupedLogs, IIPCount, ITagCount } from "../types/cves";
import CustomLineGraph from "../components/CustomLineGraph";
import { IoFilterSharp } from "react-icons/io5";
import { searchCveRepository } from "../api/searchRepository";
import { Axios, AxiosResponse } from "axios";
import groupAttacksByDay from "../utils/groupSeenOn";
import { endpointTrimmer } from "../utils/utils";
import Pagination from "../components/Pagination";
import "../assets/styles/searchCommon.css"
import { ICveDetails, ICveHistory, ICveIpLogs, ICveIpsData, ICveNIST, ICveRelatedIps } from "../types/searchCve";
import DateDropDown from "../components/DateDropDown";

export interface IpData {
    client_ip: string,
    asn: string,
    country_code: string,
    count: number,
    lastAttackDate: string
}

export async function getIpLogs(ip: string) {
    const response = await processApiProxy(ip);
    return response;
}

export default function CVE() {
    const navState = useLocation();
    const navParams = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [cveHistory, setCveHistory] = useState<ICveHistory | undefined>(undefined);
    const [cveRelatedIps, setCveRelatedIps] = useState<ICveRelatedIps | undefined>(undefined);
    const [cveIpsData, setCveIpsData] = useState<ICveIpsData | undefined>(undefined);
    const [cveIpLogs, setIpCveLogs] = useState<{ [key: string]: ICveIpLogs }>({});
    const [cveDetails, setCveDetails] = useState<ICveDetails | undefined>(undefined);
    const [cveNIST, setCveNIST] = useState<ICveNIST | undefined>(undefined);
    const toast = useToast();
    const [ipsDataPage, setIpsDataPage] = useState<number>(1);
    const [ipsLogsPage, setIpsLogsPage] = useState<number>(1);
    const [accordionOpenedIp, setAccordionOpenedIp] = useState<string>("")


    const filterDateOptions = {
        all: { index: -1, string: "All" },
        day: { index: 1, string: "Today" },
        week: { index: 7, string: "This Week" },
        month: { index: 30, string: "This Month" },
    }
    const [filterDate, setFilterDate] = useState(filterDateOptions.month)
    let endDate: number = Math.ceil((+new Date()) / 1000);
    let startDate: number = filterDate.index !== -1 ? Math.ceil(addDays(new Date(endDate * 1000), -filterDate.index).getTime() / 1000) : 0;

    function getCveIpsData(page: number) {
        searchCveRepository.getCveIpsData(navParams.searchString!!, startDate, endDate).then((response: AxiosResponse<ICveIpsData>) => {
            setCveIpsData(response.data);
        })
    }

    async function getCveLogs(ip: string, page: number = 1) {
        const cveResponse = (await searchCveRepository.getCveIpLogs(navParams.searchString!!, ip, startDate, endDate, page)).data

        setIpCveLogs(prevState => ({
            ...prevState,
            [ip]: cveResponse
        }));
    }

    useEffect(() => {
        if (navParams.searchString) {
            searchCveRepository.getCveHistory(navParams.searchString, startDate, endDate).then((response: AxiosResponse<ICveHistory>) => {
                if (response.data.seenOn.length !== 0) {
                    (response.data.seenOn as any) = groupAttacksByDay(response.data.seenOn);
                    setCveHistory(response.data)

                    searchCveRepository.getCveRelatedIps(navParams.searchString!!, startDate, endDate).then((response: AxiosResponse<ICveRelatedIps>) => {
                        setCveRelatedIps(response.data);
                    })

                    searchCveRepository.getCveDetails(navParams.searchString!!).then((response: AxiosResponse<ICveDetails>) => {
                        setCveDetails(response.data)
                    })
                    searchCveRepository.getCveNIST(navParams.searchString!!).then((response: AxiosResponse<ICveNIST[]>) => {
                        setCveNIST(response.data[0])
                    })

                    getCveIpsData(ipsDataPage);
                } else if (filterDate.index !== -1) {
                    toast({
                        title: "No results found within " + filterDate.index + " days",
                        status: "warning",
                        duration: 3000
                    })
                    setFilterDate(filterDateOptions.all)
                } else {
                    toast({
                        title: "No results found for " + navParams.searchString,
                        status: "warning",
                        duration: 3000
                    })
                }
            })
        }
    }, [navParams.searchString, filterDate]);

    useEffect(() => {
        cveIpsData && cveIpsData.data.forEach(async (ipObj) => {
            (await getCveLogs(ipObj.ip, 1));
            setIpsLogsPage(1);
        })
    }, [cveIpsData])

    useEffect(() => {
        setIsLoading(true);
        searchCveRepository.getCveIpsData(navParams.searchString!!, startDate, endDate, ipsDataPage).then((res: AxiosResponse<ICveIpsData>) => {
            setCveIpsData(res.data)
            setIsLoading(false)
        })
    }, [ipsDataPage])

    useEffect(() => {
        setIsLoading(true);
        accordionOpenedIp && getCveLogs(accordionOpenedIp, ipsLogsPage).then(() => setIsLoading(false))
    }, [ipsLogsPage])

    function getImpactStyle(impact: string) {
        switch (impact) {
            case "HIGH":
                return { color: 'rgba(246, 0, 0, 1)', scheme: "highImpact" }; // High warning or error color (red)
            case "MODERATE":
                return { color: '#faad14', scheme: "moderateImpace" }; // Warning color (orange)
            case "LOW":
                return { color: '#52c41a', scheme: "lowImpact" }; // Low warning color (green)
            default:
                return { color: '#d9d9d9', scheme: "default" }; // Default color (grey) for undefined or other values
        }
    }

    return (
        <div style={{ width: "100vw" }}>
            <Flex direction="column" className="cveMainDetails" backgroundImage={CVEBg}>
                <Heading as='h1' size='4xl' className={"cveId"}>{navParams.searchString}</Heading>
                {/* <Heading as='h1' size='2xl' color={getImpactStyle(proxyData.confidentiality_impact).color}>{proxyData.confidentiality_impact}</Heading> */}
                {/* <Divider /> */}

                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <DateDropDown setFilterDate={setFilterDate} filterDate={filterDate} />
                    <CustomLineGraph aspect={6} groupedLogs={cveHistory?.seenOn} />
                </div>
                <div className="cve-details">
                    <Flex flexDir="column">
                        <Flex width="100%" style={{ textAlign: "center" }} direction={"column"}>
                            <Flex style={{ height: "100%" }} flexDir={"row"} justifyContent="space-between" alignItems="center">
                                <Text fontSize='21px' fontWeight='700'>Exploitability Score <br />
                                </Text>
                                {/* We also need confidentiality_impact in the cve_details response for the getImpactStyle function */}
                                <Heading as='h1' fontSize='21px' fontWeight='700' padding="12px 15px" borderRadius="15px" backgroundColor={cveNIST && getImpactStyle(cveNIST.baseScore).color}>{cveNIST?.baseScore}</Heading>
                            </Flex>
                        </Flex>
                        <Tabs width="100%" className="tabsWrapper" colorScheme="grey">
                            <TabList>
                                <Tab>Description</Tab>
                                <Tab>Attack Vector</Tab>
                                <Tab>Cpe Matches</Tab>
                                <Tab>CVSS Version</Tab>
                                <Tab>Source Identifier</Tab>
                                <Tab>URLs</Tab>
                                <Tab>Vector String</Tab>
                                <Tab>Vulnerability Status</Tab>
                            </TabList>

                            <TabPanels>
                                <TabPanel>
                                    <p>{cveNIST?.description}</p>
                                </TabPanel>
                                <TabPanel>
                                    <p>{cveNIST?.attackVector}</p>
                                </TabPanel>
                                <TabPanel>
                                    <p>{cveNIST?.cpeMatches}</p>
                                </TabPanel>
                                <TabPanel>
                                    <p>{cveNIST?.cvssVersion}</p>
                                </TabPanel>
                                <TabPanel>
                                    <p>{cveNIST?.sourceIdentifier}</p>
                                </TabPanel>
                                <TabPanel>
                                    {/* Urls also need to be an array */}
                                    {cveNIST && cveNIST.urls.map((url: string, i: number) => {
                                        return <p key={i} style={{ display: "flex", alignItems: "center", columnGap: "9px" }}><a href={url} target="_blank">{url}</a><Icon as={ImNewTab} /></p>
                                    })}
                                </TabPanel>
                                <TabPanel>
                                    <p>{cveNIST?.vectorString}</p>
                                </TabPanel>
                                <TabPanel>
                                    <p>{cveNIST?.vulnerabilityStatus}</p>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Flex>
                </div>
                {cveRelatedIps &&
                    <>
                        <div className="cvePage-pies-wrapper" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <CustomPieChart data={cveRelatedIps} heading={"IPs Exploiting this attack"} />
                            </div>
                            {/* START INFO COMPONENT */}
                            <div className="outerBgBox infoComponentWrapper" style={{ width: "45%", display: "flex", flexDirection: "column", alignItems: "stretch", justifyContent: "flex-start" }}>
                                <div className="innerBgBox" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", padding: "33px" }}>
                                    <div>
                                        <Text fontSize="36px" fontWeight={700}>Tags</Text>
                                        <Flex marginTop={"12px"} direction={"column"} flexDirection={"row"} flexWrap="wrap" rowGap="12px">
                                            {cveDetails && cveDetails.tags.map(tag =>
                                                <Link
                                                    key={tag}
                                                    className="customAccordionTag"
                                                    fontSize="21px"
                                                    borderRadius={"6px"}
                                                    padding={"3px 12px"}
                                                    fontWeight={400}
                                                    href={`/tag/${tag}`}
                                                    onClick={(e) => { e.preventDefault(); window.location.href = `/tag/${tag}` }}
                                                >{tag}</Link>
                                            )}
                                        </Flex>
                                        <br />
                                        <br />
                                        <Text fontSize="36px" fontWeight={700}>Path</Text>
                                        {cveDetails && cveDetails.paths && cveDetails.paths.map((path) =>
                                            <><Text fontSize="24px" fontWeight={400}>{path}</Text><br /></>
                                        )}
                                    </div>
                                    <Flex className="cveTotalAttempts" alignItems={"center"} justifyContent={"space-between"} borderRadius={"15px"} padding={"21px 30px"} background={"linear-gradient(90deg, #900000 31.23%, #F60000 100%)"}>
                                        <Text fontSize="30px">All Time Total Attempts</Text>
                                        <Text fontSize="36px" fontWeight={"600"} letterSpacing={"9px"}>{cveDetails?.totalAttempts}</Text>
                                    </Flex>
                                </div>
                            </div>
                            {/* END INFO COMPONENT */}
                        </div>
                    </>
                }

                <Accordion allowToggle width={"100%"}>
                    {cveIpsData && cveIpsData.data.map((ipObj, i) =>
                        <CustomAccordionItem key={i}
                            accordionTitle={ipObj.ip}
                            accordionText={`${ipObj.attacks} Attacks - ${ipObj.country} - ASN${ipObj.asn} - Latest attack: ${format(new Date(ipObj.latestAttack * 1000), "yyyy-MM-dd HH:mm:ss")}`}
                            onClick={() => { setAccordionOpenedIp(ipObj.ip) }}>
                            {cveIpLogs && Object.hasOwn(cveIpLogs, ipObj.ip) && cveIpsData.data.length &&
                                <>
                                    <CustomTable
                                        headings={["DATETIME", "PATH", "USER AGENT", "REQUEST QUERYSTRING"]}
                                        rowsData={
                                            cveIpLogs[ipObj.ip].logs.map(log => [format(new Date(log.time * 1000), "yyyy-MM-dd HH:mm:ss"), log.path, log.userAgent, log.queryString])
                                        } />
                                    <Flex className="data-logs-pagination-wrapper" justifyContent={"space-between"}>
                                        <Pagination
                                            currentPage={ipsLogsPage}
                                            setPage={setIpsLogsPage}
                                            maxPage={cveIpLogs[ipObj.ip].pagination.maxPage}
                                        />
                                    </Flex>
                                </>
                            }
                        </CustomAccordionItem>
                    )}
                </Accordion>
                {cveIpsData &&
                    <Flex className="pagination-wrapper" w={"100%"} justifyContent={"space-between"}>
                        <Pagination currentPage={ipsDataPage} setPage={setIpsDataPage} maxPage={cveIpsData.pagination.maxPage} />
                    </Flex>
                }
            </Flex>
        </div>
    )
}