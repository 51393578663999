import { Table, Tbody, Td, Th, Thead, Tooltip, Tr, useToast } from "@chakra-ui/react"
import "../assets/styles/accordion.css"
import { endpointTrimmer } from "../utils/utils"


interface CustomTableProps {
    headings: string[],
    rowsData: string[][]
}

const CustomTable = ({ headings, rowsData }: CustomTableProps) => {
    const toast = useToast();
    const copyToClipboard = (textToCopy: string, element: HTMLElement) => {
        navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
                const range = document.createRange();
                range.selectNodeContents(element);
                const selection = window.getSelection();
                selection?.removeAllRanges();
                selection?.addRange(range);
    
                toast({
                    title: "Copied Successfully",
                    status: "success",
                    duration: 3000
                });
            })
            .catch((err) => {
                toast({
                    title: "Copying failed",
                    status: "error",
                    duration: 3000
                });
            });
    };

    return (
        <Table variant="simple" className="customTable" height={"100%"}>
            <Thead>
                <Tr>
                    <td className="customtable-line" style={{ height: "100%", width: "auto", padding: "0px 15px 0px 9px" }}>
                        <div style={{ height: "100%", width: "15px", borderLeft: "1px solid rgba(246, 246, 246, 1)" }}></div>
                    </td>
                    {headings.map((heading, i) => <Th key={i} fontWeight={700} fontSize={"15px"} color="rgba(246, 246, 246, 1)">{heading}</Th>)}
                </Tr>
            </Thead>
            <Tbody height={"100%"}>
                {rowsData.map((row, i) =>
                    <Tr key={i} height={"100%"}>
                        <td className="customtable-line" style={{ height: "100%", width: "auto", padding: "0px 15px 0px 9px" }}>
                            <div style={{ height: "49%", width: "15px", borderLeft: "1px solid rgba(246, 246, 246, 1)", borderBottom: "1px solid rgba(246, 246, 246, 1)" }}></div>
                            <div style={{ height: "49%", width: "15px", borderLeft: "1px solid rgba(246, 246, 246, 1)", marginTop: "auto", display: i === rowsData.length - 1 ? "none" : "block" }}></div>
                        </td>
                        {
                            row.map((data, i: number) =>
                                <Td key={i} paddingLeft="0px" onClick={(e) => copyToClipboard(data, e.currentTarget)}>
                                    <div style={{}}>
                                        <Tooltip variant="black" hasArrow placement="top" isDisabled={data.length < 30} label={data}>{endpointTrimmer(data)}</Tooltip>
                                    </div>
                                </Td>
                            )
                        }
                    </Tr>
                )}
            </Tbody>
        </Table>
    )
}

export default CustomTable