import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons"
import { background, Button } from "@chakra-ui/react"

interface IPaginationProps {
    setPage: (value: React.SetStateAction<number>) => void;
    maxPage: number;
    currentPage: number;
}
const Pagination = ({ setPage, maxPage, currentPage }: IPaginationProps) => {

    const paginationLogic = () => {
        const pages = []
        if (maxPage <= 5) {
            for (let i = 1; i <= maxPage; i++) {
                pages.push(i)
            }
        } else if (maxPage > 5 && currentPage < maxPage - 2) {
            for (let i = 1; i <= 5; i++) {
                if (currentPage > maxPage) break;
                if (currentPage == 1) {
                    pages.push(i)
                } else {
                    pages.push(currentPage + i - 2)
                }

            }
        } else {
            for (let i = maxPage - 4; i <= maxPage; i++) {
                pages.push(i)
            }
        }
        return pages;
    }

    if(paginationLogic().length === 1) return <></>

    return (
        <>
            <Button onClick={()=>setPage(1)}>First</Button>
            <Button leftIcon={<ArrowBackIcon />} onClick={() => {
                setPage((prevPage) => {
                    if (prevPage - 1 <= 0) {
                        return prevPage
                    } else {
                        return prevPage - 1
                    }
                })
            }}></Button>
            <div>
                {
                    paginationLogic().map((el) => {
                        return <Button key={el} style={(()=>{
                            if(el === currentPage) {
                                return {background: "var(--chakra-colors-whiteAlpha-300)"}
                            } else { return {}}})()} onClick={() => setPage(el)}>{el}</Button>
                    })}
            </div>
            <Button rightIcon={<ArrowForwardIcon />} onClick={() => {
                setPage((prevPage) => {
                    if (prevPage + 1 > maxPage) {
                        return prevPage
                    } else {
                        return prevPage + 1
                    }
                })
            }}></Button>
            <Button onClick={()=>setPage(maxPage)}>Last</Button>
        </>
    )
}
export default Pagination