export default function groupAttacksByDay(seenOn: number[]) {
  const startTime = +new Date()
  // Create a Map to store the counts for each day
  const countsByDay = new Map();

  // Iterate through the timestamps
  seenOn.forEach(timestamp => {
    // Create a Date object from the timestamp
    const date = new Date(timestamp * 1000);
    
    // Format the date as "DD Month YYYY"
    const formattedDate = date.toLocaleString('en-US', {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    });
    
    // Increment the count for this day
    countsByDay.set(formattedDate, (countsByDay.get(formattedDate) || 0) + 1);
  });

  // Convert the Map to an array of objects
  let result = Array.from(countsByDay, ([day, count]) => ({ day, count }));

  // If there's only one group, group by hour instead
  if (result.length === 1) {
    const countsByHour = new Map();

    seenOn.forEach(timestamp => {
      const date = new Date(timestamp * 1000);
      const formattedHour = date.toLocaleString('en-US', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        hour12: true
      });

      countsByHour.set(formattedHour, (countsByHour.get(formattedHour) || 0) + 1);
    });

    result = Array.from(countsByHour, ([hour, count]) => ({ day: hour, count }));
  }
  return result;
}