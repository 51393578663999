import { Button, FormControl, Input, useToast } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { supabase } from "../api/supabase";

interface IPasswordResetForm {
    password: string;
    confirmPassword: string;
}
export default function ResetPassword() {
    const initialRef = useRef(null)
    const toast = useToast()
    const [formData, setFormData] = useState<IPasswordResetForm>({
        password: '',
        confirmPassword: '',
    })

    const handlePasswordReset = async (e: any) => {
        e.preventDefault();
        if(formData.password != formData.confirmPassword) {
            toast({
                title: "Passwords do not match.",
                status: "warning",
                duration: 3000
            })
            return;
        }
        const { error, data } = await supabase.auth.updateUser({
            password: formData.password,
        });
        if (error) {
            toast({
                title: error.message,
                status: "warning",
                duration: 3000
            })
        } else {
            toast({
                title: "Your password has been changed. Please log-in",
                status: "success",
                duration: 3000
            })
            supabase.auth.signOut();
            setTimeout(()=> window.location.href = '', 3000);
        }
    }
    return (
        <div style={{
            margin: "60px auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
        }}>
            <FormControl>
                <form onSubmit={(e) => handlePasswordReset(e)}>
                        <Input
                            ref={initialRef}
                            placeholder='New Password'
                            size='lg'
                            marginBottom="3"
                            value={formData.password}
                            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                            type="password"
                        />
                        <Input
                            placeholder='Confirm New Password'
                            size='lg'
                            marginBottom="3"
                            value={formData.confirmPassword}
                            onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
                            type="password"
                        />
                </form>
            </FormControl>
            <Button onClick={handlePasswordReset} type="submit">
                Confirm Password Change
            </Button>
        </div>
    )
}