import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

interface CustomButtonProps extends ButtonProps {
  onClick?: () => void;
  children: React.ReactNode;
  variant?: 'black' | 'red';
}

const CustomButton: React.FC<CustomButtonProps> = ({
  onClick,
  children,
  variant = 'black',
  ...props
}) => {
  const bgColor = variant === 'black' ? 'black' : 'rgba(0,0,0,0)';
  const borderColor = variant === 'black' ? '#E41E20' : '#E41E20';

  return (
    <Button
      className="button1"
      border="1px"
      borderColor={borderColor}
      bg={bgColor}
      color="white"
      mr="2"
      p="12px 21px 12px 21px"
      lineHeight={"0.3"}
      fontWeight={400}
      onClick={onClick}
      borderRadius="4px"
      {...props}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
